import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { ContentrArea, Dated } from '@ttstr/components';

const Releases: React.FC = () => {
  const { t } = useTranslation();

  return (
    <article className="">
      <Container fluid className="m-0 p-0">
        <Helmet>
          <title>{'Releases'}</title>
          <body className="releases-page" />
        </Helmet>
        <h1 className="py-5 text-center">{'RELEASES'}</h1>
        <p className="lead">
          <ContentrArea id="copy-releases" />
        </p>
        <Row className="no-gutters kachel-container">
          <Col xs="4" sm="3">
            <a href="https://isbessa.lnk.to/BxM-allesschonokay">
              <img
                src={require('./assets/images/blinker_okey.jpeg')}
                alt="Album Cover von Blinker x Madeline Juno - Alles schon ok"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://isbessa.lnk.to/blinker_gegendieangst_album">
              <img
                src={require('./assets/images/blinker-gegen-die-angst.jpeg')}
                alt="Album Cover von Blinker - Gegen die Angst"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://isbessa.lnk.to/sm_einbisschenseele_album">
              <img
                src={require('./assets/images/madsen-bisschen-seele.jpeg')}
                alt="Album Cover von Sebastian Madsen - Ein bisschen Seele"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://isbessa.lnk.to/SebastianMadsen_Ichloesemichauf">
              <img
                src={require('./assets/images/madsen-ich-loese-mich-auf.jpeg')}
                alt="Album Cover von Sebastian Madsen - Ich löse mich auf"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://isbessa.lnk.to/Blinker-keineangstmehr-mitdirisbessa">
              <img
                src={require('./assets/images/blinker-keine-angst.jpeg')}
                alt="Album Cover von Blinker - Keine Angst mehr"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://isbessa.lnk.to/augn_very_sad_opfertyp">
              <img
                src={require('./assets/images/cover-augn.jpeg')}
                alt="Album Cover von Augn"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://isbessa.lnk.to/mariebothmer_kater">
              <img
                src={require('./assets/images/marie-bothmer-kater.jpeg')}
                alt="Album Cover von Marie Bothmer - Kater"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://isbessa.lnk.to/augn_brandenburger_wolf">
              <img
                src={require('./assets/images/cover-augn.jpeg')}
                alt="Album Cover von Augn"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://isbessa.lnk.to/augn_bitcoin">
              <img
                src={require('./assets/images/cover-augn.jpeg')}
                alt="Album Cover von Augn"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://isbessa.lnk.to/AUGN-NOKIA3210">
              <img
                src={require('./assets/images/cover-augn.jpeg')}
                alt="Album Cover von Augn"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://isbessa.lnk.to/Alias-mitdemgame">
              <img
                src={require('./assets/images/alias-mit-dem-game.jpeg')}
                alt="Album Cover von Alias - Mit dem Game"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://isbessa.lnk.to/BLOODHYPE-ONANDON">
              <img
                src={require('./assets/images/bloodhype-on-on.jpeg')}
                alt="Album Cover von Bloodhype - On & On"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://isbessa.lnk.to/KickerDibs-songefuehl">
              <img
                src={require('./assets/images/kickerdibs-songefuehl.jpeg')}
                alt="Album Cover von Kicker Dibs - Son Gefühl"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://isbessa.lnk.to/MarieBothmer-Vollnarkose">
              <img
                src={require('./assets/images/marie-bothmer-vollnarkose.jpeg')}
                alt="Album Cover von Marie Bothmer - Vollnarkose"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://isbessa.lnk.to/augn_berghain">
              <img
                src={require('./assets/images/cover-augn.jpeg')}
                alt="Album Cover von Augn"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://isbessa.lnk.to/sm_einbisschenseele_single">
              <img
                src={require('./assets/images/madsen-seele.jpg')}
                alt="Album Cover von Sebastian Madsen - Ein bisschen Seele"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://isbessa.lnk.to/bh_glamour">
              <img
                src={require('./assets/images/bloodhype-glamour.jpg')}
                alt="Album Cover von Bloodhype - Glamour"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://isbessa.lnk.to/blinker_gaslaternen">
              <img
                src={require('./assets/images/blinker-gaslaternen.jpg')}
                alt="Album Cover von Sebastian Blinker - Gaslaternen"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/sm_immernuramhandy">
              <img
                src={require('./assets/images/sebastian-madsen-handy.jpg')}
                alt="Album Cover von Sebastian Madsen - Immer nur am Handy"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/tuttibounce-syableman">
              <img
                src={require('./assets/images/tuttibounce-jackie.png')}
                alt="Album Cover von Tutti Bounce - Sy Ableman"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/mariebothmer-swimmingpool-ep">
              <img
                src={require('./assets/images/mariebothmer-swimmingpool.jpg')}
                alt="Album Cover von Marie Bothmer - Swimmingpool EP"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/sm-drangsal-seinurduselbst">
              <img
                src={require('./assets/images/sebastian-madsen-sei-nur-du-selbst.jpg')}
                alt="Album Cover von Sebastian Madsen - Sei nur du selbst (feat. Drangsal)"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/beatsteaks-kommando-sunshine">
              <img
                src={require('./assets/images/beatsteaks-kommando-sunshine.jpg')}
                alt="Album Cover von Beatsteaks - Kommando Sunshine"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/blinker-sagdemleben">
              <img
                src={require('./assets/images/blinker-sag-dem-leben.jpg')}
                alt="Album Cover von Blinker - Sag dem Leben"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/honza-romano-bockaufclub">
              <img
                src={require('./assets/images/honza-romano-bockaufclub.jpeg')}
                alt="Album Cover von Honza x Romano - Bock auf Club"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/tuttibounce-tatooinetransit">
              <img
                src={require('./assets/images/tuttibounce-tatooinetransit.jpeg')}
                alt="Album Cover von Tutti Bounce - Tatooine Transit"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/alias-fearofgod">
              <img
                src={require('./assets/images/alias-fearofgod.jpeg')}
                alt="Album Cover von Ali As - Fear of God"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/mariebothmer-kugelsicher">
              <img
                src={require('./assets/images/mariebothmer-kugelsicher.jpeg')}
                alt="Album Cover von Marie Bothmer - Kugelsicher"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/blinker-nebendir">
              <img
                src={require('./assets/images/blinker-nebendir.png')}
                alt="Album Cover von Blinker - Neben Dir"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/honza-sauber">
              <img
                src={require('./assets/images/honza-sauber.jpeg')}
                alt="Album Cover von Honza - Sauber"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/mariebothmer-filmriss">
              <img
                src={require('./assets/images/mariebothmer-filmriss.jpeg')}
                alt="Album Cover von Marie Bothmer - Filmriss"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/jpd-feelings-ep">
              <img
                src={require('./assets/images/jpd-feelings.jpeg')}
                alt="Album Cover von JPD - Feelings"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://open.spotify.com/track/4TqCgH7oCxCEqt4K9YxPdm?si=7eaa7ad7612f42339">
              <img
                src={require('./assets/images/jpd-ohboy.png')}
                alt="Album Cover von JPD - Oh Boy"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/romano-emfgs">
              <img
                src={require('./assets/images/einmann.jpg')}
                alt="Album Cover von Romano - Ein Mann für gewisse Stunden"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/mariebothmer-swimmingpool">
              <img
                src={require('./assets/images/bothmer.jpg')}
                alt="Album Cover von Marie Bothmer - Swimmingpool"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/jpd-himmelblau">
              <img
                src={require('./assets/images/himmelblau.jpg')}
                alt="Album Cover von JPD - Himmelblau"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/alias-glaubst-du-an-geister">
              <img
                src={require('./assets/images/alias.jpg')}
                alt="Album Cover von Ali As - Glaubst du an Geister"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/blinker-issues-akustik">
              <img
                src={require('./assets/images/blinker.jpg')}
                alt="Album Cover von Blinker - Issues"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/vlude-waikiki-pianoversion">
              <img
                src={require('./assets/images/vlude.jpg')}
                alt="Album Cover von VLUDE - Waikiki (Pianoversion)"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/tuttibounce-leguan-feat-porky">
              <img
                src={require('./assets/images/leguan.jpg')}
                alt="Album Cover von Tuttibounce - Leguan feat. Porky"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://open.spotify.com/track/28td51uup9YgSdrZavX4DV?si=b6669c3cba534398">
              <img
                src={require('./assets/images/jpd-jaja.jpeg')}
                alt="Album Cover von JPD - Ja Ja (1,5 Grad)"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://open.spotify.com/track/5byXMp6Km0Gyob1GbxxEsx?si=8b111377e5a14df7">
              <img
                src={require('./assets/images/blinker-truelove.jpeg')}
                alt="Album Cover von Blinker - True Love"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
          <Col xs="4" sm="3">
            <a href="https://orcd.co/honza-okay-hxremix">
              <img
                src={require('./assets/images/honza.jpg')}
                alt="Album Cover von Honza - Okay Hx Remix"
                className="w-100 img-fluid cover-image"
              />
            </a>
          </Col>
        </Row>
        <ContentrArea id="label-main" />
      </Container>
    </article>
  );
};

export default React.memo(Releases);
