import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { ContentrArea, Video } from '@ttstr/components';

const Label: React.FC = () => {
  const { t } = useTranslation();

  return (
    <article className="mb-0">
      <Video
        preload="auto"
        muted // autoPlay only works in Safari when muted
        autoPlay
        disablePictureInPicture
        loop={true}
        playsInline
        nativeControls={false}
        controls
        controlColor="light"
        controlOutline
        controlsList="nodownload nofullscreen noremoteplayback"
        poster={require('./assets/videos/isbessa-poster.jpg')}
        containerClassName="d-none d-md-block sticky-top fold-behind"
        className="fullsize-video"
      >
        <source src={require('./assets/videos/isbessa.mp4')} type="video/mp4" />
      </Video>

      <Video
        preload="auto"
        muted // autoPlay only works in Safari when muted
        autoPlay
        disablePictureInPicture
        loop={true}
        playsInline
        nativeControls={false}
        controls
        controlColor="light"
        controlOutline
        controlsList="nodownload nofullscreen noremoteplayback"
        poster={require('./assets/videos/isbessa-poster.jpg')}
        containerClassName="d-block d-md-none sticky-top fold-behind"
        className="fullsize-video"
      >
        <source src={require('./assets/videos/isbessa.mp4')} type="video/mp4" />
      </Video>
      <section className="label-about pt-4">
        <Container>
          <Helmet>
            <title>{'Über Isbessa'}</title>
            <body className="label-page" />
          </Helmet>

          <ContentrArea id="above-content" />

          <Row>
            <Col md="6">
              <img
                src={require('./assets/images/isbessa-mietke.jpg')}
                alt="ISBESSA Team"
                className="w-100 img-fluid mb-3"
              />
              <h3 className="mb-2">{'Volker Mietke'}</h3>
              <p className="mb-3">
                <ContentrArea id="copy-mietke" />
              </p>
            </Col>
            <Col md="6">
              <img
                src={require('./assets/images/isbessa-beele.jpg')}
                alt="ISBESSA Team"
                className="w-100 img-fluid mb-3"
              />
              <h3 className="mb-2">{'Markus Beele'}</h3>
              <p className="mb-3">
                <ContentrArea id="copy-beele" />
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </article>
  );
};

export default React.memo(Label);
