import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Container, ContentrArea, useIntl, Masonry2, SimpleCard } from '@ttstr/components';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import { Brand } from '@ttstr/api';
import { CellProps } from '@ttstr/components/Listing/Cells/Cell';
import { requestBrands } from '@ttstr/actions';
import { AppState } from '@ttstr/reducers';

type Props = {
  brandFilter?: (b: Brand) => any;
};

const BrandsView: React.FC<Props> = ({ brandFilter }) => {
  const { t } = useTranslation();
  const { brands, brandsReceived } = useShallowEqualSelector(mapStateToProps);
  const { requestBrands } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    if (!brandsReceived) requestBrands();
  }, [language]);

  return (
    <article className="brand-listing">
      <Container fluid className="list-page m-0 p-0">
        <Helmet>
          <title>{t(`BRANDLISTING.TITLE`)}</title>
          <body className="brand-listing-page" />
        </Helmet>
        <h1 className="py-5 text-center">KÜNSTLER</h1>
        <p className="lead">
          <ContentrArea id="copy-artists" />
        </p>
        <Masonry2
          id="brand-masonry"
          baseURLofDetailSite="/brand"
          cellData={Object.values(brands)
            .filter((brand: Brand) => brand.show_in_frontend === true)
            .sort((a, b) => new Intl.Collator(language).compare(a.title, b.title)) // first sort alphabetically
            .sort((a, b) => b.feature - a.feature) // then sort the highest feature to top of list
            .map(
              (brand: Brand): CellProps => ({
                id: brand.id,
                title: brand.title,
                image: brand.image,
              })
            )}
          cellComponentType={SimpleCard}
        />

        <ContentrArea id="after-listing" />
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { brands, brandsReceived } = state.Brands;
  return {
    brands,
    brandsReceived,
  };
};

const mapDispatchToProps = {
  requestBrands,
};

export default React.memo(BrandsView);
