import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { ContentrArea } from '@ttstr/components';

const Landing: React.FC = () => {
  const { t } = useTranslation();

  return (
    <article>
      <Container fluid className="m-0 p-0">
        <Helmet>
          <title>Willkommen bei ISBESSA</title>
          <body className="landing-page" />
        </Helmet>
        <div className="elfsight-app-553fed7b-f0ca-420b-aeb9-f087e0300365"></div>
      </Container>
    </article>
  );
};

export default React.memo(Landing);
